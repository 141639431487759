export const cardSx = (theme) => ({
  minWidth: '90%',
  backgroundColor: theme.palette.background.secondary,
  flex: '0 0 50%',
  margin: '16px',
  borderRadius: '2.5%',
});

export const avatarSx = (theme) => ({
  width: 56,
  height: 56,
  border: `solid 4px ${theme.palette.tertiary.main}`,
  img: {
    filter: 'grayscale(100%)',
  },
});

export const headerSx = (theme) => ({
  '.MuiTypography-root': {
    color: theme.palette.secondary.main,
  },
  '.MuiCardHeader-subheader': {
    fontSize: '14px',
  },
});

export const bioSx = {
  fontWeight: 400,
};
