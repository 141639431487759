import { Box, useTheme } from '@mui/material';
import { Text } from '../Text/Text';
import {
  contentSx,
  contentContainerSx,
  containerSx,
  headingSx,
  titleSx,
  mediaContainerSx,
  imageContainerSx,
  imgSx,
} from './ContentCard.style';
import { Image } from '../Image/Image';
import { Heading } from '../Heading/Heading';
import { Quote } from '../../icons/Quote/Quote';

export const ContentCard = ({
  title,
  heading,
  content,
  img,
  imgDimensions,
  video,
  isSecondaryCard,
  isReversed,
  isColumn,
  children,
  isTop,
  isQuote,
}) => {
  const theme = useTheme();
  const bgcolor = isSecondaryCard && theme.palette.secondary.main;

  const color = isSecondaryCard
    ? theme.palette.primary.main
    : theme.palette.secondary.main;

  return (
    <Box component="section" sx={{ bgcolor }}>
      <Box component="div" sx={containerSx(theme, isReversed, isColumn, isTop)}>
        <Box
          className={isTop ? 'animate__animated animate__fadeInLeft' : ''}
          component="div"
          sx={
            img || video ? contentContainerSx(theme, isReversed, isColumn) : {}
          }
        >
          {isQuote ? (
            <Quote />
          ) : (
            <Heading
              level={1}
              text={title}
              color={theme.palette.tertiary.main}
              sx={titleSx}
            />
          )}

          <Heading level={2} text={heading} sx={headingSx} color={color} />

          {content && <Text text={content} color={color} sx={contentSx} />}

          {children && children}
        </Box>

        <Box
          sx={mediaContainerSx}
          className={isTop ? 'animate__animated animate__fadeInRight' : ''}
        >
          {img && (
            <Box component="div" sx={imageContainerSx(theme)}>
              <Image
                img={img}
                alt={`${img} image`}
                imgDimensions={imgDimensions}
                sx={imgSx}
              />
            </Box>
          )}

          {video && video}
        </Box>
      </Box>
    </Box>
  );
};
