import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';

export const Redirect = ({ url }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.open(url);
    navigate(APP_PATHS.home);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return null;
};
