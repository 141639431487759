import * as Yup from 'yup';
import { Copy } from '../copy/Copy';

export const CONTACT_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().trim().required(Copy.generate('Errors.required')),
  company: Yup.string().trim().required(Copy.generate('Errors.required')),
  email: Yup.string()
    .email(Copy.generate('Errors.invalidEmail'))
    .required(Copy.generate('Errors.required')),
  phone: Yup.string().trim().required(Copy.generate('Errors.required')),
});
