import { MailOutline, Instagram, LinkedIn } from '@mui/icons-material';
import { IconLink } from '../IconLink/IconLink';
import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { flexCenterSx, iconLinkSx } from '../../styles/Global.style';

export const SocialLinks = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...flexCenterSx, ...sx }}>
      <IconLink
        icon={Instagram}
        href={Copy.generate('Socials.instagram')}
        sx={iconLinkSx(theme)}
        aria="Instagram link"
      />
      <IconLink
        icon={LinkedIn}
        href={Copy.generate('Socials.linkedin')}
        sx={iconLinkSx(theme)}
        aria="Linkedin link"
      />

      <IconLink
        icon={MailOutline}
        href={Copy.generate('Global.emailLink')}
        sx={iconLinkSx(theme)}
        aria="email link"
      />
    </Box>
  );
};
