import { flexCenterSx } from '../../styles/Global.style';

export const cardSx = (theme, color) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '400px',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '2.5%',
  padding: '16px',
  flex: 1,
  border: `solid 2px ${color}`,
  [`@media only screen and (max-width: 990px)`]: {
    width: '100%',
    margin: '16px auto',
  },
});

export const headingSx = { fontSize: '24px !important', marginBottom: '8px' };

export const textSx = { marginBottom: '16px' };

export const listSx = { padding: 0, marginBottom: '32px' };

export const buttonSx = { ...flexCenterSx, marginTop: 'auto' };
