import {
  ListItem as Item,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { listItemTextSx } from './ListItem.style';

export const ListItem = ({
  component = Link,
  text,
  to,
  onClick,
  icon,
  itemTextSx,
  itemSx,
}) => {
  const theme = useTheme();

  return (
    <Item
      component={component}
      to={to}
      sx={itemSx}
      onClick={onClick}
      disablePadding
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}

      <ListItemText
        primary={text}
        primaryTypographyProps={listItemTextSx({
          theme,
          itemTextSx,
        })}
      />
    </Item>
  );
};
