import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTheme } from '@mui/material';

export const Vision = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <RemoveRedEyeIcon
      fontSize={size}
      sx={{ color: theme.palette.tertiary.main }}
    />
  );
};
