export const inputAdornmentSx = (theme) => ({
  '.MuiTypography-root': {
    color: theme.palette.secondary.main,
  },
});

export const textFieldSx = (theme) => ({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    '& fieldset': {
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.secondary.main,
    },
    '&.Mui-focused fieldset': {
      color: theme.palette.secondary.main,
    },
    '& ::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
});
