import { Box, List, useTheme } from '@mui/material';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { Tagline } from '../Tagline/Tagline';
import { Logo } from '../../icons/Logo/Logo';
import { marginBottom16Sx, navListSx } from '../../styles/Global.style';
import { Text } from '../Text/Text';
import {
  copyRightSx,
  footerContainerSx,
  navListItemTextSx,
} from './Footer.style';
import { Copy } from '../../copy/Copy';
import { NavListItems } from '../NavListItems/NavListItems';
import { FOOTER_LIST_ITEMS } from '../../constants/footerListItems';

export const Footer = () => {
  const theme = useTheme();

  return (
    <Box component="footer" sx={footerContainerSx}>
      <Box component="div" sx={marginBottom16Sx}>
        <Logo />
      </Box>

      <SocialLinks sx={marginBottom16Sx} />

      <Tagline sx={marginBottom16Sx} />

      <List sx={navListSx}>
        <NavListItems
          listItems={FOOTER_LIST_ITEMS}
          itemTextSx={navListItemTextSx}
        />
      </List>

      <Text
        text={Copy.generate('Global.copyright')}
        color={theme.palette.secondary.main}
        sx={copyRightSx}
      />
    </Box>
  );
};
