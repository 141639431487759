import { useTheme } from '@mui/material';

export const Logo = ({ width = 50, height = 50 }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 375 375"
    >
      <path fill={theme.palette.primary.main} d="M-37.5-37.5h450v450h-450z" />
      <path fill={theme.palette.primary.main} d="M-37.5-37.5h450v450h-450z" />
      <path fill={theme.palette.primary.main} d="M-37.5-37.5h450v450h-450z" />
      <path
        fill={theme.palette.primary.main}
        d="M-10.922-20.926h276.024v247.403H-10.922V-20.926"
      />
      <path
        fill={theme.palette.secondary.main}
        d="m147.574 163.858-.343-64.016-31.063 52.11h-15.187L70.09 101.56v62.297H38.511V43.06h28.125l42.453 69.719 41.422-69.72h28.125l.344 120.798Zm0 0"
      />
      <path
        d="M335.294 141.48h-17.906v23.094h-33.265v-23.093h-63.75v-22.22l54.109-75.312h35.312l-49.109 70.485h24.469v-20.5h32.234v20.5h17.906Zm0 0"
        fill="#f000ff"
      />
      <path
        fill={theme.palette.secondary.main}
        d="m147.647 319.752-.344-63.844-30.985 51.969h-15.14l-30.797-50.25v62.125h-31.5V199.283h28.047l42.344 69.531 41.296-69.531h28.047l.344 120.469ZM333.575 198.879v121.093h-34.25v-47.218h-46.703v47.218h-34.25V198.88h34.25v45.5h46.703v-45.5Zm0 0"
      />
    </svg>
  );
};
