import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { APP_PATHS } from '../constants/paths';
import { About } from '../pages/About/About';
import { Contact } from '../pages/Contact/Contact';
import { Help } from '../pages/Help/Help';
import { DEMO_LINK } from '../constants/links';
import { Redirect } from '../components/Redirect/Redirect';
import { Pricing } from '../pages/Pricing/Pricing';
import { Feedback } from '../pages/Feedback/Feedback';
import { Privacy } from '../pages/Privacy/Privacy';

export const Routes = () => (
  <Switch>
    <Route path={APP_PATHS.home} element={<Home />} />
    <Route path={APP_PATHS.about} element={<About />} />
    <Route path={APP_PATHS.contact} element={<Contact />} />
    <Route path={APP_PATHS.help} element={<Help />} />
    <Route path={APP_PATHS.feedback} element={<Feedback />} />
    <Route path={APP_PATHS.pricing} element={<Pricing />} />
    <Route path={APP_PATHS.privacy} element={<Privacy />} />
    <Route path="/demo" element={<Redirect url={DEMO_LINK} />} />
    <Route path="*" element={<Navigate to={APP_PATHS.home} replace />} />
  </Switch>
);
