import { Box, useTheme } from '@mui/material';
import { Button } from '../../components/Button/Button';
import { Copy } from '../../copy/Copy';
import { buttonSx } from '../../styles/Global.style';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { PromoVideo } from '../../components/PromoVideo/PromoVideo';
import { DEMO_LINK } from '../../constants/links';
import { FeatureList } from '../../components/FeatureList/FeatureList';
import { splashImgDimensions } from './Home.style';

export const Home = () => {
  const theme = useTheme();

  return (
    <>
      <ContentCard
        img="/assets/img/splash.webp"
        imgDimensions={splashImgDimensions}
        title={Copy.generate('Global.appName')}
        heading={Copy.inject('Home.splash.heading', {
          yourMind: dynamicTextColor(
            'nourish the mind.',
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Home.splash.content')}
        isTop
      >
        <ContactUsButton />
      </ContentCard>

      <ContentCard
        title={Copy.generate('Home.video.title')}
        heading={Copy.generate('Home.video.heading')}
        video={<PromoVideo />}
        isSecondaryCard
        isReversed
      />

      <ContentCard
        img="/assets/img/award.webp"
        title={Copy.generate('Home.award.title')}
        heading={Copy.inject('Home.award.heading', {
          positive: dynamicTextColor('positive', theme.palette.quaternary.main),
        })}
        content={Copy.generate('Home.award.content')}
      />

      <ContentCard
        img="/assets/img/demo.webp"
        title={Copy.generate('Home.demo.title')}
        heading={Copy.inject('Home.demo.heading', {
          fitness: dynamicTextColor('fitness.', theme.palette.quaternary.main),
        })}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.demo')}
            onClick={() => window.open(DEMO_LINK)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>

      <ContentCard
        heading={Copy.inject('Home.quotes.1.title', {
          motivated: dynamicTextColor(
            'It motivated me',
            theme.palette.tertiary.main,
          ),
        })}
        content={Copy.generate('Home.quotes.1.content')}
        isColumn
        isQuote
      />

      <ContentCard
        title={Copy.generate('Home.features.title')}
        heading={Copy.inject('Home.features.heading', {
          onePlace: dynamicTextColor(
            'in one place.',
            theme.palette.quaternary.main,
          ),
        })}
        isSecondaryCard
        isColumn
      >
        <FeatureList />
      </ContentCard>

      <ContentCard
        heading={Copy.inject('Home.quotes.2.title', {
          benefits: dynamicTextColor(
            'physical and mental benefits',
            theme.palette.tertiary.main,
          ),
        })}
        content={Copy.generate('Home.quotes.2.content')}
        isColumn
        isQuote
      />

      <ContentCard
        heading={Copy.inject('Home.contactUs.content', {
          weBring: dynamicTextColor(
            "We'll bring the challenge.",
            theme.palette.quaternary.main,
          ),
        })}
        isSecondaryCard
        isColumn
      >
        <ContactUsButton />
      </ContentCard>
    </>
  );
};
