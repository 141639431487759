export const tertiaryHoverSx = (theme) => ({
  '&:hover': {
    color: theme.palette.tertiary.main,
  },
});

export const buttonSx = (theme) => ({
  color: theme.palette.default.main,
  backgroundColor: theme.palette.tertiary.main,
  '&:hover': {
    backgroundColor: theme.palette.quaternary.main,
  },
});

export const flexCenterSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const marginRight12Sx = { marginRight: '12px' };

export const marginTop16Sx = {
  marginTop: '16px',
};

export const centerTextSx = {
  textAlign: 'center',
};

export const centerText15pxMarginSx = {
  textAlign: 'center',
  margin: '15px',
};

export const font500Sx = {
  fontWeight: '500',
};

export const font800Sx = {
  fontWeight: '800',
};

export const font900Sx = {
  fontWeight: '900',
};

export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '32px',
};

export const boxContainerSx = {
  width: '100%',
  overflow: 'hidden',
  flexDirection: 'column',
  ...flexCenterSx,
};

export const marginTop24Sx = { marginTop: '24px' };

export const marginBottom24Sx = { marginBottom: '24px' };

export const marginBottom12Sx = { marginBottom: '12px' };

export const marginBottom32Sx = { marginBottom: '32px' };

export const formInputSx = (theme) => ({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.secondary.main,
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.secondary.main,
    },
    '&.Mui-focused fieldset': {
      color: theme.palette.secondary.main,
    },
    '& ::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
});

export const inputLabelPropsSx = (theme, isDisableLabel) => ({
  style: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  shrink: isDisableLabel,
});

export const mediaCardSx = (theme) => ({
  maxWidth: '345px',
  minWidth: '345px',
  backgroundColor: theme.palette.background.secondary,
  flex: '0 0 50%',
  margin: '16px',
  borderRadius: '2.5%',
});

export const mediaCardHeaderSx = (theme) => ({
  color: `${theme.palette.secondary.main} !important`,
});

export const mediaCardTypographySx = {
  style: {
    fontSize: '20px',
  },
};

export const mediaContainerSx = (isMobile) => ({
  ...flexCenterSx,
  flexDirection: isMobile ? 'column' : 'row',
  marginBottom: '80px',
  flexFlow: 'row wrap',
});

export const marginBottom16Sx = { marginBottom: '16px' };

export const iconLinkSx = (theme) => ({
  color: theme.palette.secondary.main,
  margin: 'auto 16px',
  cursor: 'pointer',
  ...tertiaryHoverSx(theme),
  [theme.breakpoints.up('xs')]: {
    fontSize: '24px',
    margin: 'auto 8px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
    margin: 'auto 8px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    margin: 'auto 12px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '32px',
    margin: 'auto 12px',
  },
});

export const borderHoverSx = (theme) => ({
  display: 'inline-block',
  '&:after': {
    display: 'block',
    content: '""',
    borderBottom: `solid 3px ${theme.palette.tertiary.main}`,
    transform: 'scaleX(0)',
    transition: 'transform 400ms ease-in-out',
  },
  '&:hover:after': { transform: 'scaleX(1)', transformOrigin: '0% 50%' },
});

export const splitContainerSx = (isMobile) => ({
  maxWidth: '1100px',
  margin: '0 auto',
  padding: '24px',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
});

export const splitContentContainerSx = (isMobile) => ({
  flex: '1 0 50%',
  paddingRight: isMobile ? '0px' : '48px',
});

export const splitFormContainerSx = {
  flex: '1 0 50%',
};

export const navListSx = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};
