import { Copy } from '../copy/Copy';
import { Challenge } from '../icons/Challenge/Challenge';
import { Music } from '../icons/Music/Music';
import { Profile } from '../icons/Profile/Profile';
import { Customise } from '../icons/Customise/Customise';
import { Community } from '../icons/Community/Community';
import { ChatGPT } from '../icons/ChatGPT/ChatGPT';

export const FEATURES = [
  {
    icon: Profile,
    heading: Copy.generate('Home.features.list.1.title'),
    content: Copy.generate('Home.features.list.1.content'),
  },
  {
    icon: Community,
    heading: Copy.generate('Home.features.list.2.title'),
    content: Copy.generate('Home.features.list.2.content'),
  },
  {
    icon: Challenge,
    heading: Copy.generate('Home.features.list.3.title'),
    content: Copy.generate('Home.features.list.3.content'),
  },
  {
    icon: Customise,
    heading: Copy.generate('Home.features.list.4.title'),
    content: Copy.generate('Home.features.list.4.content'),
  },
  {
    icon: Music,
    heading: Copy.generate('Home.features.list.5.title'),
    content: Copy.generate('Home.features.list.5.content'),
  },
  {
    icon: ChatGPT,
    heading: Copy.generate('Home.features.list.6.title'),
    content: Copy.generate('Home.features.list.6.content'),
  },
];
