import { useTheme } from '@emotion/react';
import { AppBar, Box, List, Toolbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { Burger } from '../../icons/Burger/Burger';
import { Close } from '../../icons/Close/Close';
import { Logo } from '../../icons/Logo/Logo';
import { IconBtn } from '../IconBtn/IconBtn';
import { NavListItems } from '../NavListItems/NavListItems';
import {
  appBarSx,
  navListContainerSx,
  logoSx,
  navListItemTextSx,
  toolbarSx,
  burgerSx,
  contactSx,
} from './NavBar.style';
import { Contact } from '../../icons/Contact/Contact';
import { NAV_LIST_ITEMS } from '../../constants/navListItems';
import { navListSx } from '../../styles/Global.style';

export const NavBar = ({ isNavMenuOpen, toggleMenuBtn, isMobile }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const color = theme.palette.secondary.main;
  const backgroundColor = theme.palette.background.default;

  const onContactClick = () => {
    navigate(APP_PATHS.contact);
    isNavMenuOpen && toggleMenuBtn();
  };

  return (
    <AppBar position="sticky" sx={{ ...appBarSx, backgroundColor }}>
      <Toolbar disableGutters sx={toolbarSx}>
        {isMobile && (
          <Box sx={burgerSx}>
            <IconBtn
              icon={isNavMenuOpen ? <Close /> : <Burger />}
              onClick={toggleMenuBtn}
              color={color}
              aria="Menu button"
            />
          </Box>
        )}

        <Link
          to={APP_PATHS.home}
          style={logoSx}
          onClick={isNavMenuOpen && toggleMenuBtn}
          aria-label="Link to Home page"
        >
          <Logo color={color} />
        </Link>

        {isMobile && (
          <Box sx={contactSx}>
            <IconBtn
              icon={<Contact />}
              onClick={onContactClick}
              color={color}
              aria="Contact us button"
            />
          </Box>
        )}

        {!isMobile && (
          <Box sx={navListContainerSx}>
            <List sx={navListSx}>
              <NavListItems
                listItems={NAV_LIST_ITEMS}
                itemTextSx={navListItemTextSx}
              />
            </List>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
