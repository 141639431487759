import {
  MAX_PRICE_PER_USER,
  MAX_USERS,
  MID_PRICE_PER_USER,
  MID_USERS,
  MIN_PRICE_PER_USER,
  MIN_USERS,
} from '../constants/pricing';

export const calculatePricePerUser = (userCount) => {
  const minMidFraction =
    (MIN_PRICE_PER_USER - MID_PRICE_PER_USER) / (MID_USERS - MIN_USERS);

  const minMidAdditionalUsers = userCount - MIN_USERS;

  const midMaxFraction =
    (MID_PRICE_PER_USER - MAX_PRICE_PER_USER) / (MAX_USERS - MID_USERS);

  const midMaxAdditionalUsers = userCount - MID_USERS;

  if (userCount >= MIN_USERS && userCount <= MID_USERS) {
    return MIN_PRICE_PER_USER - minMidAdditionalUsers * minMidFraction;
  } else if (userCount >= MID_USERS && userCount <= MAX_USERS) {
    return MID_PRICE_PER_USER - midMaxAdditionalUsers * midMaxFraction;
  }
};
