export const APP_PATHS = {
  home: '/',
  about: '/about',
  team: '/team',
  contact: '/contact',
  help: '/help',
  pricing: '/pricing',
  demo: '/demo',
  feedback: '/feedback',
  privacy: '/privacy-policy',
};
