import { Box } from '@mui/material';
import { FEATURES } from '../../constants/features';
import { Feature } from '../Feature/Feature';
import { featureListContainerSx } from './FeatureList.style';

export const FeatureList = () => (
  <Box component="div" sx={featureListContainerSx}>
    {FEATURES.map((f, i) => (
      <Feature key={i} icon={f.icon} heading={f.heading} content={f.content} />
    ))}
  </Box>
);
