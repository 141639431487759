import GroupIcon from '@mui/icons-material/Group';
import { useTheme } from '@mui/material';

export const Users = ({ size = 'small' }) => {
  const theme = useTheme();

  return (
    <GroupIcon fontSize={size} sx={{ color: theme.palette.secondary.main }} />
  );
};
