import { CardMedia } from '@mui/material';

export const Image = ({ id, img, alt, sx, imgDimensions }) => (
  <CardMedia
    id={id}
    component="img"
    src={img}
    width={imgDimensions?.width}
    height={imgDimensions?.height}
    alt={alt}
    sx={sx}
  />
);
