import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { useTheme } from '@mui/material';

export const GBP = ({ size = 'small' }) => {
  const theme = useTheme();

  return (
    <CurrencyPoundIcon
      fontSize={size}
      sx={{ color: theme.palette.secondary.main }}
    />
  );
};
