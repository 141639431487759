import { Copy } from '../copy/Copy';
import { APP_PATHS } from './paths';
import { Hyperlink } from '../components/Hyperlink/Hyperlink';

export const HELP_ACCORDIONS = [
  {
    heading: Copy.generate('Help.questions.accordions.1.heading'),
    content: Copy.inject('Help.questions.accordions.1.content', {
      pricing: (
        <Hyperlink
          to={APP_PATHS.pricing}
          text={Copy.generate('Links.pricing')}
        />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.2.heading'),
    content: Copy.inject('Help.questions.accordions.2.content', {
      contact: (
        <Hyperlink
          to={APP_PATHS.contact}
          text={Copy.generate('Global.contactUs')}
        />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.3.heading'),
    content: Copy.inject('Help.questions.accordions.3.content', {
      demo: (
        <Hyperlink to={APP_PATHS.demo} text={Copy.generate('Links.demo')} />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.4.heading'),
    content: Copy.generate('Help.questions.accordions.4.content'),
  },
  {
    heading: Copy.generate('Help.questions.accordions.5.heading'),
    content: Copy.generate('Help.questions.accordions.5.content'),
  },
  {
    heading: Copy.generate('Help.questions.accordions.6.heading'),
    content: Copy.generate('Help.questions.accordions.6.content'),
  },
];
