import { Copy } from '../copy/Copy';
import { Rocket } from '../icons/Rocket/Rocket';
import { Value } from '../icons/Value/Value';
import { Vision } from '../icons/Vision/Vision';

export const MISSION = [
  {
    icon: Rocket,
    heading: Copy.generate('About.mission.list.1.title'),
    content: Copy.generate('About.mission.list.1.content'),
  },
  {
    icon: Vision,
    heading: Copy.generate('About.mission.list.2.title'),
    content: Copy.generate('About.mission.list.2.content'),
  },
  {
    icon: Value,
    heading: Copy.generate('About.mission.list.3.title'),
    content: Copy.generate('About.mission.list.3.content'),
  },
];
