import { Box, CardMedia } from '@mui/material';
import { promoVideoContainerSx } from './PromoVideo.style';

export const PromoVideo = () => (
  <Box component="div" sx={promoVideoContainerSx}>
    <CardMedia
      component="iframe"
      src="https://www.youtube-nocookie.com/embed/f5f3yiUav9g?color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  </Box>
);
