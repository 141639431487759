import { Box, Slider } from '@mui/material';
import { useState } from 'react';
import { calculatePricePerUser } from '../../helpers/calculatePricePerUser';
import { Hr } from '../Hr/Hr';
import { PricingCalculatorTextField } from '../PricingCalculatorTextField/PricingCalculatorTextField';
import {
  containerSx,
  fieldContainerSx,
  hr1Sx,
  hr2Sx,
} from './PricingCalculator.style';
import { Users } from '../../icons/Users/Users';
import { GBP } from '../../icons/GBP/GBP';
import { Copy } from '../../copy/Copy';

export const PricingCalculator = () => {
  const [userCount, setUserCount] = useState(20);

  const handleChange = (event) => {
    setUserCount(event.target.value);
  };

  return (
    <Box sx={containerSx}>
      <Slider
        aria-label="Always visible"
        defaultValue={20}
        getAriaValueText={(value) => `${value} users`}
        step={1}
        min={20}
        max={250}
        valueLabelDisplay="on"
        color="quaternary"
        value={userCount}
        onChange={handleChange}
      />

      <Hr sx={hr1Sx} />

      <Box sx={fieldContainerSx}>
        <PricingCalculatorTextField
          label={Copy.generate('Inputs.numberOfUsers')}
          value={userCount}
          icon={<Users />}
        />

        <PricingCalculatorTextField
          label={Copy.generate('Inputs.pricePerUser')}
          value={calculatePricePerUser(userCount).toFixed(2)}
          icon={<GBP />}
        />

        <PricingCalculatorTextField
          label={Copy.generate('Inputs.totalPerMonth')}
          value={(calculatePricePerUser(userCount) * userCount).toFixed(2)}
          icon={<GBP />}
        />
      </Box>

      <Hr sx={hr2Sx} />
    </Box>
  );
};
