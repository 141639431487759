export const privacySectionSx = {
  color: '#121212',
  backgroundColor: '#fff',
  padding: '32px 20px 32px 20px',
};

export const privacyContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1100px',
  margin: '0 auto',
};
