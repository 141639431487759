import { LibraryMusic } from '@mui/icons-material';
import { useTheme } from '@mui/material';

export const Music = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <LibraryMusic fontSize={size} sx={{ color: theme.palette.tertiary.main }} />
  );
};
