import { InputAdornment, TextField, useTheme } from '@mui/material';
import {
  inputAdornmentSx,
  textFieldSx,
} from './PricingCalculatorTextField.style';
import { inputLabelPropsSx } from '../../styles/Global.style';

export const PricingCalculatorTextField = ({ label, value, icon }) => {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      type="number"
      fullWidth
      label={label}
      color="tertiary"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={inputAdornmentSx(theme)} position="start">
            {icon}
          </InputAdornment>
        ),
        readOnly: true,
      }}
      sx={textFieldSx(theme)}
      InputLabelProps={inputLabelPropsSx(theme)}
    />
  );
};
