import { useTheme } from '@mui/material';
import { ListItem } from '../ListItem/ListItem';
import { borderHoverSx } from '../../styles/Global.style';
import { navListItemSx } from './NavListItems.style';

export const NavListItems = ({ listItems, itemTextSx, onClick }) => {
  const theme = useTheme();

  return (
    <>
      {listItems?.map((item) => (
        <ListItem
          key={item.name}
          text={item.name}
          to={item.to}
          theme={theme}
          itemSx={navListItemSx}
          itemTextSx={{ ...itemTextSx, ...borderHoverSx(theme) }}
          onClick={onClick}
        />
      ))}
    </>
  );
};
