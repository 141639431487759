import { Box, Card, List, useTheme } from '@mui/material';
import { Heading } from '../Heading/Heading';
import { ContactUsButton } from '../ContactUsButton/ContactUsButton';
import { ListItem } from '../ListItem/ListItem';
import { Check } from '../../icons/Check/Check';
import {
  buttonSx,
  cardSx,
  headingSx,
  listSx,
  textSx,
} from './CoreFeatures.style';
import { Copy } from '../../copy/Copy';
import { Text } from '../Text/Text';

export const CoreFeatures = () => {
  const theme = useTheme();
  const color = theme.palette.tertiary.main;

  return (
    <Card sx={cardSx(theme, color)}>
      <Heading
        level={1}
        text={Copy.generate('CoreFeatures.heading')}
        color={color}
        sx={headingSx}
      />
      <Text
        text={Copy.generate('CoreFeatures.text')}
        color={theme.palette.secondary.main}
        sx={textSx}
      />

      <List sx={listSx}>
        {Copy.generateArray('CoreFeatures.features').map((f, i) => (
          <ListItem component="li" key={i} icon={<Check />} text={f} />
        ))}
      </List>

      <Box sx={buttonSx}>
        <ContactUsButton />
      </Box>
    </Card>
  );
};
