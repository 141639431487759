export const promoVideoContainerSx = {
  overflow: 'hidden',
  width: '100%',
  aspectRatio: '16/9',
  margin: 'auto',
  iframe: {
    width: ' 320%',
    height: '100%',
    marginLeft: '-110%',
  },
};
