import { Box, Stack, useTheme } from '@mui/material';
import { Heading } from '../../components/Heading/Heading';
import { containerSx } from '../../styles/Global.style';
import { formTitleSx } from './FormContainer.style';

export const FormContainer = ({ children, title, id, className, sx }) => {
  const theme = useTheme();

  return (
    <Stack
      id={id}
      component="section"
      sx={{ ...containerSx, ...sx }}
      className={className}
    >
      <Box component="div">
        <Heading text={title} level={4} sx={formTitleSx(theme)} />
        {children}
      </Box>
    </Stack>
  );
};
