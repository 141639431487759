import { Box, useTheme } from '@mui/material';
import { Heading } from '../Heading/Heading';
import {
  getInTouchContainerSx,
  getInTouchHeadingSx,
  getInTouchTextSx,
  getInTouchTitleSx,
  giveUsACallContainerSx,
  giveUsACallSx,
} from './GetInTouch.style';
import { Copy } from '../../copy/Copy';
import { Text } from '../Text/Text';
import { Button } from '../Button/Button';
import { buttonSx } from '../../styles/Global.style';
import { Call } from '../../icons/Call/Call';
import { HelperLink } from '../HelperLink/HelperLink';
import { APP_PATHS } from '../../constants/paths';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';

export const GetInTouch = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={getInTouchContainerSx}
      className="animate__animated animate__fadeInLeft"
    >
      <Heading
        level={1}
        text={Copy.generate('Global.contactUs')}
        color={theme.palette.tertiary.main}
        sx={getInTouchTitleSx}
      />

      <Heading
        text={Copy.inject('GetInTouch.title', {
          betterMentalHealth: dynamicTextColor(
            Copy.generate('GetInTouch.betterMentalHealth'),
            theme.palette.quaternary.main,
          ),
        })}
        level={2}
        sx={getInTouchHeadingSx(theme)}
      />

      <Box component="div" sx={giveUsACallContainerSx}>
        <Heading
          text={Copy.generate('GetInTouch.giveUsACall')}
          level={3}
          sx={giveUsACallSx(theme)}
        />

        <Text
          text={Copy.generate('Global.openHours')}
          color={theme.palette.secondary.main}
          sx={getInTouchTextSx}
        />

        <Button
          component="a"
          text={Copy.generate('Global.telephone')}
          href={`tel:${Copy.generate('Global.telephone')}`}
          sx={buttonSx(theme)}
          startIcon={<Call />}
        />
      </Box>

      <HelperLink
        to={APP_PATHS.help}
        textIdentifier="GetInTouch.needHelp"
        linkText={Copy.generate('GetInTouch.getHelp')}
        sx={getInTouchTextSx}
      />
    </Box>
  );
};
