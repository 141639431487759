import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tertiaryHoverSx } from '../../styles/Global.style';
import { Text } from '../Text/Text';
import { helperLinkSx, helperLinkTextSx } from './HelperLink.style';
import { Copy } from '../../copy/Copy';

export const HelperLink = ({ to, textIdentifier, linkText, onClick, sx }) => {
  const theme = useTheme();

  return (
    <>
      <Text
        text={Copy.inject(textIdentifier, {
          getHelpLink: (
            <Link to={to} style={helperLinkSx} onClick={onClick}>
              <Text
                component="span"
                text={linkText}
                color={theme.palette.tertiary.main}
                sx={{
                  ...tertiaryHoverSx(theme),
                  ...helperLinkTextSx(theme),
                  ...sx,
                }}
              />
            </Link>
          ),
        })}
        color={theme.palette.secondary.main}
        sx={sx}
      />
    </>
  );
};
