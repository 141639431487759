import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import { PricingCalculator } from '../../components/PricingCalculator/PricingCalculator';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';
import {
  splitContainerSx,
  splitContentContainerSx,
} from '../../styles/Global.style';
import { CoreFeatures } from '../../components/CoreFeatures/CoreFeatures';
import { coreFeaturesContainerSx } from './Pricing.style';

export const Pricing = () => {
  const theme = useTheme();
  const { windowSize } = useWindowSize();
  const { isMobile } = useIsMobile();

  return (
    <>
      <ContentCard
        title={Copy.generate('Pricing.title')}
        heading={Copy.inject('Pricing.heading', {
          gotYou: dynamicTextColor(
            'we got you.',
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Pricing.content')}
        isColumn
        isTop
      >
        <Box sx={splitContainerSx(isMobile)}>
          <Box
            sx={splitContentContainerSx(isMobile, windowSize.appInnerHeight)}
          >
            <PricingCalculator />
          </Box>

          <Box sx={coreFeaturesContainerSx}>
            <CoreFeatures />
          </Box>
        </Box>
      </ContentCard>

      <ContentCard
        heading={Copy.inject('Pricing.contactUs.content', {
          flexible: dynamicTextColor(
            "We're flexible.",
            theme.palette.quaternary.main,
          ),
        })}
        isSecondaryCard
        isColumn
      >
        <ContactUsButton />
      </ContentCard>
    </>
  );
};
