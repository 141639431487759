export const appBarSx = {
  height: '90px',
  padding: '16px',
  boxShadow: 'none',
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%)',
};

export const toolbarSx = {
  display: 'flex',
  maxWidth: '1100px',
  width: '100%',
  margin: '0 auto',
};

export const burgerSx = {
  flex: 1,
};

export const contactSx = {
  flex: 1,
  justifyContent: 'right',
  display: 'flex',
};

export const navListContainerSx = {
  marginLeft: 'auto',
  width: '600px',
};

export const navListItemTextSx = {
  fontSize: '16px',
  margin: '0 16px',
};

export const logoSx = {
  textAlign: 'center',
  height: 50,
  width: 50,
};
