import { Copy } from '../copy/Copy';

export const FEEDBACK_INIT_VALUES = {
  email: '',
  feedbackReason: '',
  feedback: '',
};

export const FEEDBACK_SELECT_OPTIONS = [
  {
    label: Copy.generate('Feedback.feedbackReason.like'),
    value: 'LIKE',
  },
  {
    label: Copy.generate('Feedback.feedbackReason.dislike'),
    value: 'DISLIKE',
  },
  {
    label: Copy.generate('Feedback.feedbackReason.feature'),
    value: 'FEATURE REQUEST',
  },
];
