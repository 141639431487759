import { Box } from '@mui/material';
import { Feature } from '../Feature/Feature';
import { missionListContainerSx } from './MissionList.style';
import { MISSION } from '../../constants/mission';

export const MissionList = () => (
  <Box component="div" sx={missionListContainerSx}>
    {MISSION.map((f, i) => (
      <Feature key={i} icon={f.icon} heading={f.heading} content={f.content} />
    ))}
  </Box>
);
