import { useState } from 'react';
import { ContactUsForm } from '../../components/ContactUsForm/ContactUsForm';
import { Success } from '../../components/Success/Success';
import { Copy } from '../../copy/Copy';
import { GetInTouch } from '../../components/GetInTouch/GetInTouch';
import { Box } from '@mui/material';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';
import {
  splitContainerSx,
  splitContentContainerSx,
  splitFormContainerSx,
} from '../../styles/Global.style';

export const Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { windowSize } = useWindowSize();
  const { isMobile } = useIsMobile();

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  return (
    <Box component="section" sx={splitContainerSx(isMobile)}>
      <Box
        component="div"
        sx={splitContentContainerSx(isMobile, windowSize.appInnerHeight)}
      >
        <GetInTouch />
      </Box>

      <Box component="div" sx={splitFormContainerSx}>
        {!isSubmitted && <ContactUsForm handleSubmit={handleSubmit} />}

        {isSubmitted && (
          <Success
            title={Copy.generate('Global.thanks')}
            text={Copy.generate('Contact.success.text')}
          />
        )}
      </Box>
    </Box>
  );
};
