import { Avatar, Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { Text } from '../Text/Text';
import { IconLink } from '../IconLink/IconLink';
import { LinkedIn } from '@mui/icons-material';
import { avatarSx, bioSx, cardSx, headerSx } from './TeamMember.style';
import { iconLinkSx } from '../../styles/Global.style';

export const TeamMember = ({ name, img, bio, href }) => {
  const theme = useTheme();

  return (
    <Card sx={cardSx(theme)}>
      <CardHeader
        avatar={
          <Avatar
            alt={name}
            src={`/assets/img/${img}.webp`}
            sx={avatarSx(theme)}
          />
        }
        action={
          <IconLink
            icon={LinkedIn}
            href={href}
            sx={iconLinkSx(theme)}
            aria="Linkedin link"
          />
        }
        title={name}
        subheader="Co-founder"
        sx={headerSx}
      />
      <CardContent>
        <Text text={bio} color={theme.palette.secondary.main} sx={bioSx} />
      </CardContent>
    </Card>
  );
};
