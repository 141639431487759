import { Box, useTheme } from '@mui/material';
import { Button } from '../Button/Button';
import { Copy } from '../../copy/Copy';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { buttonSx } from '../../styles/Global.style';

export const ContactUsButton = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box>
      <Button
        text={Copy.generate('Global.contactUs')}
        onClick={() => navigate(APP_PATHS.contact)}
        sx={buttonSx(theme)}
      />
    </Box>
  );
};
